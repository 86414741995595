import PropTypes from "prop-types"
import React, { useEffect, Image, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"

import useForm from "../../../../_shared/hooks/useForm"
import StyledImageUploadInput from "../../../../_shared/components/FormItems/ImageUploadInput"
import StyledUploadedImagePreview from "../../../../_shared/components/UploadedImagePreview"
import { FaFilePdf, FaImage } from "react-icons/fa"
import { Switch, DatePicker, Radio, Form, Modal } from "antd"
import moment from "moment"
import AppButton from "../../../../_shared/components/AppButton"
import RichText from "../../../../_shared/components/FormItems/WYSIWYG"
import FloatingButton from "../../../../_shared/components/FloatingButton"
import { AiOutlineClose } from "react-icons/ai"

const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

function CouponsFormBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, post, onConfirm, coupons, ...sheetProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialValues = post ?? {}

    const [formValues, setFormValue, setFormValues, clearForm] =
        useForm(initialValues)
    const [person, setPerson] = useState()

    useEffect(() => {
        if (open) {
            setFormValues(post ?? {})
        } else {
            clearForm()
        }
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            console.log(">>> handleFormValueChange: ", key, value)

            setFormValue(key, value)
        }
    }

    function handleConfirm() {
        onConfirm?.(formValues)
    }

    function addPeople() {
        handleFormValueChange("addresses")([
            ...(formValues?.addresses ?? []),
            person,
        ])
        setPerson("")
    }

    function removePerson(index) {
        const oldArray = [...formValues?.addresses]
        oldArray?.splice(index, 1)
        handleFormValueChange("addresses")(oldArray)
    }

    function renderEmail(email, index) {
        return (
            <div className="email-row">
                {email}
                <FloatingButton
                    className="close-icn"
                    onClick={() => removePerson(index)}
                >
                    <AiOutlineClose className="icn" />
                </FloatingButton>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            className={props.className}
            open={open}
            snapPoint={80}
            title={post?.id ? "Modifica Coupon" : "Nuovo Coupon"}
            onConfirm={handleConfirm}
            {...sheetProps}
        >
            <div>
                <h3
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    UTILIZZO
                </h3>
                <Radio.Group
                    name="radiogroup"
                    defaultValue={true}
                    value={formValues?.shopCoupon}
                    style={{ marginBottom: 40 }}
                    onChange={(e) =>
                        handleFormValueChange("shopCoupon")(e?.target?.value)
                    }
                >
                    <Radio value={true}>In negozio</Radio>
                    <Radio value={false}>Online</Radio>
                </Radio.Group>
                <Input
                    className="form-control"
                    label="Nome"
                    value={formValues.name}
                    onChange={handleFormValueChange("name")}
                />
                <Input
                    className="form-control"
                    label="Codice"
                    value={formValues.code}
                    onChange={handleFormValueChange("code")}
                />
                <div style={{ marginBottom: 20 }}>
                    <Input
                        className="form-control"
                        label="Indirizzo email"
                        value={person}
                        onChange={setPerson}
                    />
                    <div style={{ marginTop: 20 }}>
                        {formValues?.addresses?.map((address, index) =>
                            renderEmail(address, index)
                        )}
                    </div>

                    <AppButton onClick={() => addPeople()}>
                        Aggiungi mail
                    </AppButton>
                </div>
                <text>Descrizione coupon</text>
                <RichText
                    className="input inner"
                    label="Testo"
                    value={formValues?.description ?? ""}
                    onChange={handleFormValueChange("description")}
                />
                <Input
                    className="form-control"
                    label="Codice EAN"
                    value={formValues.EAN}
                    onChange={handleFormValueChange("EAN")}
                />
                <Input
                    className="form-control"
                    label="Minimo di punti"
                    value={formValues.minPoints}
                    onChange={handleFormValueChange("minPoints")}
                />
                <div
                    style={{
                        display: "flex",
                        width: 200,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Input
                        label="Valore"
                        style={{ width: 100 }}
                        value={formValues.value}
                        onChange={handleFormValueChange("value")}
                    />
                    <Radio.Group
                        name="radiogroup"
                        defaultValue={1}
                        value={parseInt(formValues?.currency)}
                        style={{ marginLeft: 20 }}
                        onChange={(e) =>
                            handleFormValueChange("currency")(e?.target?.value)
                        }
                    >
                        <Radio value={1}>€</Radio>
                        <Radio value={2}>%</Radio>
                    </Radio.Group>
                </div>
                <h3
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 30,
                    }}
                >
                    RANGE DI VALIDITÀ
                </h3>
                {/*  */}

                {
                    <RangePicker
                        format="DD-MM-YYYY"
                        defaultValue={[
                            moment(post?.validDate?.[0]),
                            moment(post?.validDate?.[1]),
                        ]}
                        showTime
                        onChange={(range) => {
                            const isoDates = range?.map((date) =>
                                date?.toISOString?.()
                            )
                            handleFormValueChange("validDate")(isoDates)
                        }}
                    />
                }

                <h3
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 30,
                    }}
                >
                    IMMAGINE ANTEPRIMA
                </h3>
                <div>
                    <StyledImageUploadInput
                        onUpload={handleFormValueChange("image")}
                    ></StyledImageUploadInput>
                    {typeof formValues?.image === "string" && (
                        <img
                            alt=""
                            style={{
                                marginLeft: 50,
                                maxHeight: 200,
                                objectFit: "contain",
                            }}
                            src={formValues?.image}
                        />
                    )}
                </div>
                <h3
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 30,
                    }}
                >
                    IMMAGINE Dettaglio
                </h3>
                <div>
                    <StyledImageUploadInput
                        onUpload={handleFormValueChange("detailImage")}
                    ></StyledImageUploadInput>
                    {typeof formValues?.detailImage === "string" && (
                        <img
                            alt=""
                            style={{
                                marginLeft: 50,
                                maxHeight: 200,
                                objectFit: "contain",
                            }}
                            src={formValues?.detailImage}
                        />
                    )}
                </div>
            </div>
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CouponsFormBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    post: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
}

CouponsFormBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const CouponsFormBottomSheetWithStyle = styled(CouponsFormBottomSheet)`
    & {
        .sheet-content {
            max-width: 1000px;

            .form-control {
                margin-bottom: 20px;
                width: 500px;
                max-width: 500px;
            }
        }
        .pdf-icn {
            margin-top: 50px;
            margin-left: 50px;
            font-size: 50px;
            color: ${(props) => props?.theme?.colors?.dropzoneTextColor};
        }
        .close-icn {
            width: 20px;
            height: 20px;
        }
        .email-row {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 3px;
        }
    }
`
// ----------------------------------------------------------------------------

export default CouponsFormBottomSheetWithStyle
