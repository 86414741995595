import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Popconfirm, Tag } from "antd"
import dayjs from "dayjs"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import CategoriesBottomSheet from "./CategoriesBottomSheet"
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { MenuOutlined } from "@ant-design/icons"

// ----------------------------------------------------------------------------

function Categories(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [
        categories,
        fetchCategories,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource("/storeden/categories", token)

    const [created, setCreated] = useState(false)

    const [
        internalCategories,
        fetchInternalCategories,
        loadingInternal,
        paginationInternal,
        totalRecordsNumberInternal,
        onFiltersChangeInternal,
    ] = usePaginatedRestResource("/categories", token, {
        pageSize: 100,
    })

    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categoriesData, setCategoriesData] = useState(null)

    useEffect(() => {
        if (!loading && categories?.length > 1) {
            // setCreated(true)
            const mainCategories = categories
                ?.filter((x) => !x?.parent)
                ?.filter((x) => !x?.disabled)
            createAllMainCategories(mainCategories)
        }
    }, [categories])

    useEffect(() => {
        if (!loadingInternal && internalCategories?.length > 1) {
            setCategoriesData(
                internalCategories?.sort((a, b) => a.sortIndex - b.sortIndex)
            )
        }
    }, [internalCategories])

    async function createAllMainCategories(mainCategories) {
        mainCategories?.map(async (x, index) => {
            !internalCategories?.find((x) => x?.uid === x?.uid) &&
                (await createMainCategory(x, index))
        })

        await fetchInternalCategories()
        setCategoriesData(
            internalCategories?.sort((a, b) => a.sortIndex - b.sortIndex)
        )
    }

    async function createMainCategory(cat, index) {
        const body = {
            uid: cat?.uid,
            name: cat?.name,
            createdInApp: false,
            sortIndex: index,
        }

        await api.createResource("/categories", token, body)
    }

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleCreateCategory(category) {
        try {
            if (category?.target) {
                category.uid = category.target
            }
            let body = {
                ...category,
                createdInApp: true,
            }
            console.log("body : ", body)

            if (category.image) {
                const uploadResponse = await api.uploadFile(category.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.createResource("/categories", token, body)
            setSelectedCategory(null)
            message.success("Articolo creato con successo")
            fetchInternalCategories()
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdateCategory(category) {
        try {
            if (category?.target) {
                category.uid = category.target
            }
            let body = {
                ...category,
            }

            if (category.image && typeof category.image !== "string") {
                const oldImage = categories?.find(
                    (item) => item?.id === category.id
                )?.image

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                await api.deleteStoredenFile(imageId, token)

                const uploadResponse = await api.uploadFile(category.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.updateResource(
                `/categories`,
                token,
                selectedCategory.id,
                body
            )
            setSelectedCategory(null)
            message.success("Articolo aggiornato con successo")
            fetchInternalCategories()
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdateIndex(category) {
        try {
            let body = {
                ...category,
            }

            console.log("handleUpdateIndex : ", body)

            await api.updateResource(`/categories`, token, body.id, body)
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleCategoryBottomSheetConfirm(category) {
        if (category.id) {
            return handleUpdateCategory(category)
        } else {
            return handleCreateCategory(category)
        }
    }

    async function handleDeleteCategory(category) {
        try {
            if (!category?.id) {
                return
            }

            await api.deleteResource("/categories", token, category.id)

            message.success("Articolo eliminato con successo")
            fetchInternalCategories()
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleCategoryBottomSheetCancel() {
        setSelectedCategory(null)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                {row?.createdInApp && (
                    <>
                        <EditButton onClick={() => setSelectedCategory(row)} />
                        <Popconfirm
                            onConfirm={() => handleDeleteCategory(row)}
                            placement="left"
                            title="Sei sicuro di voler eliminare questo articolo?"
                        >
                            <DeleteButton />
                        </Popconfirm>
                    </>
                )}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const defaultCategory = {}

    function setSortIndex(subCategory, index) {
        const newBody = {
            ...internalCategories?.find((x) => x.id === subCategory.id),
            sortIndex: index,
        }
        console.log("new body: ", newBody)

        handleUpdateIndex(newBody, false)
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setCategoriesData((prev) => {
                const activeIndex = prev.findIndex((i) => i.id === active.id)
                const overIndex = prev.findIndex((i) => i.id === over?.id)
                const newArray = arrayMove(prev, activeIndex, overIndex)
                newArray?.map((x, index) => setSortIndex(x, index))

                return newArray
            })
        }
    }
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        })
    )

    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props["data-row-key"],
        })
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                }
            ),
            transition,
            ...(isDragging
                ? {
                      position: "relative",
                      zIndex: 9999,
                  }
                : {}),
        }
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === "sort") {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: "none",
                                        cursor: "move",
                                    }}
                                    {...listeners}
                                />
                            ),
                        })
                    }
                    return child
                })}
            </tr>
        )
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Articoli</h1>

            <AppButton
                uxType="secondary"
                onClick={() => setSelectedCategory(defaultCategory)}
            >
                Nuova categoria
            </AppButton>
            {categoriesData && (
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        // rowKey array
                        items={categoriesData?.map((i) => i.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            dataSource={categoriesData}
                            pagination={{
                                ...paginationInternal,
                                total: categoriesData?.length,
                            }}
                            // onChange={(pagination) =>
                            //     // fetchCategories({ pagination })
                            // }
                            columns={getColumns(renderActions)}
                            loading={loading}
                            rowKey={(row) => row.id}
                            components={{
                                body: {
                                    row: Row,
                                },
                            }}
                        />

                        <CategoriesBottomSheet
                            open={selectedCategory ? true : false}
                            category={selectedCategory}
                            onCancel={handleCategoryBottomSheetCancel}
                            onConfirm={handleCategoryBottomSheetConfirm}
                        />
                    </SortableContext>
                </DndContext>
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Categories.propTypes = {
    className: PropTypes.string.isRequired,
}

Categories.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCategories = styled(Categories)`
    & {
        .actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledCategories

function getColumns(renderActions) {
    return [
        {
            key: "sort",
        },
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
        },

        {
            key: "actions",
            render: renderActions,
        },
    ]
}
